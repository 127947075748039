import { useSelector } from 'react-redux'

import { RootState } from '../store'

const useContentsSelector = () => useSelector((state: RootState) => state.contentModel.contentData)

const useContentFilesSelector = () =>
  useSelector((state: RootState) => state.contentModel.contentFilesData)

const useCurrentFolderSelector = () =>
  useSelector((state: RootState) => state.contentModel.currentFolder)

const useContentLoadingSelector = () =>
  useSelector((state: RootState) => state.contentModel.isContentLoading)

const useInnerContentLoadingSelector = () =>
  useSelector((state: RootState) => state.contentModel.isInnerContentLoading)

const usePromtTemplateSelector = () =>
  useSelector((state: RootState) => state.contentModel.templatesData)

const usePromptHistorySelector = () =>
  useSelector((state: RootState) => state.contentModel.promptHistory)

const useChatMessageSelector = () =>
  useSelector((state: RootState) => state.contentModel.chatMessage)

const useLLMModelSelector = () => useSelector((state: RootState) => state.contentModel.llmModelList)

const usePromptDetailsSelector = () =>
  useSelector((state: RootState) => state.contentModel.promptData)

const useappDataSelector = () => useSelector((state: RootState) => state.contentModel.appData)

const useAppDesignDataSelector = () =>
  useSelector((state: RootState) => state.contentModel.appDesignerData)

const useCurrentAppDetailsSelector = () =>
  useSelector((state: RootState) => state.contentModel.currentAppDetails)

const useTestStatusSelector = () => useSelector((state: RootState) => state.contentModel.testStatus)

const useQuerySelector = () => useSelector((state: RootState) => state.contentModel.queryUrl)

const useAppHistorySelector = () => useSelector((state: RootState) => state.contentModel.appHistory)

const useAppVersionSelector = () =>
  useSelector((state: RootState) => state.contentModel.appVersions)

const useAppStatusSelector = () =>
  useSelector((state: RootState) => state.contentModel.bulkRunStatus)

const useBulkRunstatus = () =>
  useSelector((state: RootState) => state.contentModel.isBulkRunContentLoading)

const useRunHistorySelector = () => useSelector((state: RootState) => state.contentModel.runHistory)

const useTagsSelector = () => useSelector((state: RootState) => state.contentModel.Tags)

const useAppShareLinkGeneration = () =>
  useSelector((state: RootState) => state.contentModel.appShareLinkGeneration)
// const usePromptTagsSelector = ()=>
// useSelector((state:RootState)=>state.contentModel.promptTags)

const useAppShareLinkValidation = () =>
  useSelector((state: RootState) => state.contentModel.appShareLinkValidation)
const useBulkRunResultDynamic = () =>
  useSelector((state: RootState) => state.contentModel.bulkRunResultDynamic)

const useVisionModelSelector = () =>
  useSelector((state: RootState) => state.contentModel.visionModelList)

const useFileParams = () => useSelector((state: RootState) => state.contentModel.fileParams)

const useenduserresult = () => useSelector((state: RootState) => state.contentModel.enduserresult)
const useAppApiUrl = () => useSelector((state: RootState) => state.contentModel.appApiUrl)
const useUpdatedUrlforCsvUpload = () =>
  useSelector((state: RootState) => state.contentModel.updatedUrlforCsvUpload)
const useVectorDb = () => useSelector((state: RootState) => state.contentModel.vectorDB)
const useUserListSelector = () => useSelector((state: RootState) => state.contentModel.userList)
const useGetUSerDetailsSelector = () =>
  useSelector((state: RootState) => state.contentModel.userDetails)
const useListTenantSelector = () => useSelector((state: RootState) => state.contentModel.tenantList)
const useListRolesSelectors = () => useSelector((state: RootState) => state.contentModel.rolesList)
const useListAccessSelectors = () =>
  useSelector((state: RootState) => state.contentModel.accessList)
const useGetTenantDetails = () =>
  useSelector((state: RootState) => state.contentModel.tenantDetails)
const usePermissionValues = () =>
  useSelector((state: RootState) => state.contentModel.permissionValues)
const useListPackagesSelectors = () =>
  useSelector((state: RootState) => state.contentModel.packageList)
const useListProductsSelectors = () =>
  useSelector((state: RootState) => state.contentModel.productList)
const useGetViewUserSelector = () => useSelector((state: RootState) => state.contentModel.viewUser)
const useGetIntegrationList = () =>
  useSelector((state: RootState) => state.contentModel.integrationList)
const useGetMasterIntegrationList = () =>
  useSelector((state: RootState) => state.contentModel.masterIntegrationList)
const useFeatureSettingSelector = () =>
  useSelector((state: RootState) => state.contentModel.featureSettings)
const useTicketDetailsSelector = () =>
  useSelector((state: RootState) => state.contentModel.ticketDetail)
const useAutoTagListSelector = () =>
  useSelector((state: RootState) => state.contentModel.autoTagData)
const useendPointDataSelector = () =>
  useSelector((state: RootState) => state.contentModel.endpointdata)
const useTenantTeamSelectors = () =>
  useSelector((state: RootState) => state.contentModel.tenantGroup)
const useLoaderSelector = () => useSelector((state: RootState) => state.contentModel.loader)
const useFaqDataSelector = () => useSelector((state: RootState) => state.contentModel.faqData)
const usePastTicketDataSelector = () =>
  useSelector((state: RootState) => state.contentModel.pastTicketData)
const useAgentAssistAppSelector = () =>
  useSelector((state: RootState) => state.contentModel.agentAssistApps)
const useFaqPtTicketDataSelector = () =>
  useSelector((state: RootState) => state.contentModel.ticketFaqPtDetails)
const useEmbeddingModelListSelector = () =>
  useSelector((state: RootState) => state.contentModel.embeddingModelList)
const useSubscriptionDetailsSelector = () =>
  useSelector((state: RootState) => state.contentModel.subscriptionDetails)

const useTopIntentsSelector = () => useSelector((state: RootState) => state.contentModel.topIntents)

const useTopIncreasedIntentsSelector = () =>
  useSelector((state: RootState) => state.contentModel.topIncreasedIntents)

const useTopDecreasedIntentsSelector = () =>
  useSelector((state: RootState) => state.contentModel.topDecreasedIntents)

const useTotalIntentsSelector = () =>
  useSelector((state: RootState) => state.contentModel.topTotalIntents)

const useTicketsBasedonIntent = () =>
  useSelector((state: RootState) => state.contentModel.ticketsBasedonIntent)

const useGetCurrentModule = () =>
  useSelector((state: RootState) => state.contentModel.currentModule)
const useIntentsGraphData = () =>
  useSelector((state: RootState) => state.contentModel.intentsGraphData)

const useIntentsListData = () => useSelector((state: RootState) => state.contentModel.intentLists)

const useTimetoResponse = () => useSelector((state: RootState) => state.contentModel.timetoResponse)

const useTimetoResolve = () => useSelector((state: RootState) => state.contentModel.timetoResolve)

const useTicketEscallation = () =>
  useSelector((state: RootState) => state.contentModel.ticketEscallation)

const useFirstContactResolution = () =>
  useSelector((state: RootState) => state.contentModel.firstContactResolution)

const useCommonTags = () => useSelector((state: RootState) => state.contentModel.commonTags)

const useCustomerHealthSelector = () =>
  useSelector((state: RootState) => state.contentModel.customerHealth)
const useChatBotHistorySelector = () =>
  useSelector((state: RootState) => state.contentModel.chatBotHistory)

const useListAllIntents = () => useSelector((state: RootState) => state.contentModel.allIntents)

const useControlroomFilter = () =>
  useSelector((state: RootState) => state.contentModel.controlroomFilter)

const useAppInsights = () => useSelector((state: RootState) => state.contentModel.appInsights)

const useBotDetailsSelector = () => useSelector((state: RootState) => state.contentModel.botDetails)
const useWidgetListSelector = () => useSelector((state: RootState) => state.contentModel.widgetList)
const useWidgetTypesSelector = () =>
  useSelector((state: RootState) => state.contentModel.widgetTypes)
const useAgentListSelector = () => useSelector((state: RootState) => state.contentModel.agentList)

const useSelectedToolsSelector = () =>
  useSelector((state: RootState) => state.contentModel.selectedTools)

const useGetWidgetDetails = () =>
  useSelector((state: RootState) => state.contentModel.widgetDetails)

const useAutoSolveRunHistory = () =>
  useSelector((state: RootState) => state.contentModel.AutosolveRunHistory)

const useAutoSolveRunHistoryConversations = () =>
  useSelector((state: RootState) => state.contentModel.AutosolveRunHistoryConversations)
const useAgentConversationsList = () =>
  useSelector((state: RootState) => state.contentModel.agentConversationList)
const useAgentConversationDetail = () =>
  useSelector((state: RootState) => state.contentModel.agentConversationDetail)
const useGetInstalltionListSelector = () =>
  useSelector((state: RootState) => state.contentModel.installationList)

const useGenerateCodeSelector = () =>useSelector((state: RootState) => state.contentModel.generateCode)
const useGetFileUrl = () =>useSelector((state: RootState) => state.contentModel.fileURL)


const useChatBotHistoryLoaderSelector = ()=>
  useSelector((state:RootState)=> state.contentModel.isChatBotHistoryLoader)

const useLearnMoreSelector = ()=>
  useSelector((state:RootState)=> state.contentModel.learnMore)

const useTerminateApiUrlSelector = ()=>
  useSelector((state:RootState)=> state.contentModel.terminateUrl)

const useCleanupResponseSelector=()=>
  useSelector((state:RootState)=>state.contentModel.cleanupResponse)

const useAutosolveConversationDetail = () => useSelector((state: RootState) => state.contentModel.autoSolveConversationDetail)
const useAgentDetails = () => useSelector((state: RootState) => state.contentModel.agentDetails)


export {
  useAgentAssistAppSelector,
  useContentsSelector,
  useContentFilesSelector,
  useCurrentFolderSelector,
  useContentLoadingSelector,
  usePromtTemplateSelector,
  usePromptHistorySelector,
  useChatMessageSelector,
  useLLMModelSelector,
  usePromptDetailsSelector,
  useappDataSelector,
  useAppDesignDataSelector,
  useCurrentAppDetailsSelector,
  useTestStatusSelector,
  useQuerySelector,
  useAppHistorySelector,
  useAppVersionSelector,
  useAppStatusSelector,
  useBulkRunstatus,
  useRunHistorySelector,
  useTagsSelector,
  useAppShareLinkGeneration,
  useAppShareLinkValidation,
  useBulkRunResultDynamic,
  useVisionModelSelector,
  useFileParams,
  useenduserresult,
  useAppApiUrl,
  useUpdatedUrlforCsvUpload,
  useVectorDb,
  useUserListSelector,
  useGetUSerDetailsSelector,
  useListTenantSelector,
  useListRolesSelectors,
  useListAccessSelectors,
  useGetTenantDetails,
  usePermissionValues,
  useListPackagesSelectors,
  useListProductsSelectors,
  useGetViewUserSelector,
  useGetIntegrationList,
  useGetMasterIntegrationList,
  useFeatureSettingSelector,
  useTicketDetailsSelector,
  useAutoTagListSelector,
  useendPointDataSelector,
  useTenantTeamSelectors,
  useLoaderSelector,
  useFaqDataSelector,
  usePastTicketDataSelector,
  useFaqPtTicketDataSelector,
  useEmbeddingModelListSelector,
  useSubscriptionDetailsSelector,
  useTopIntentsSelector,
  useTotalIntentsSelector,
  useTopIncreasedIntentsSelector,
  useTopDecreasedIntentsSelector,
  useTicketsBasedonIntent,
  useGetCurrentModule,
  useIntentsGraphData,
  useIntentsListData,
  useTimetoResponse,
  useTimetoResolve,
  useTicketEscallation,
  useFirstContactResolution,
  useCommonTags,
  useCustomerHealthSelector,
  useListAllIntents,
  useChatBotHistorySelector,
  useControlroomFilter,
  useAppInsights,
  useBotDetailsSelector,
  useWidgetListSelector,
  useWidgetTypesSelector,
  useAgentListSelector,
  useSelectedToolsSelector,
  useGetWidgetDetails,
  useAutoSolveRunHistory,
  useAutoSolveRunHistoryConversations,
  useInnerContentLoadingSelector,
  useAgentConversationsList,
  useAgentConversationDetail,
  useGetInstalltionListSelector,
  useGenerateCodeSelector,
  useChatBotHistoryLoaderSelector,
  useLearnMoreSelector,
  useTerminateApiUrlSelector,
  useCleanupResponseSelector,
  useAutosolveConversationDetail,
  useAgentDetails,
  useGetFileUrl
}
