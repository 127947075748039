import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons';
import { BlobServiceClient } from '@azure/storage-blob'
import jwt_decode from 'jwt-decode'
import { token } from 'stylis'
import { format } from 'date-fns';
import { store } from './redux/store'


let routerHistory: { [key: string]: any } = {
  navigate: null,
  location: null,
}
interface ITokenDecoded {
  iss: string
}

const extractIdToken = (tokenString: string = '') => tokenString.slice(tokenString.indexOf('=') + 1)

const convertBytestoMB = (sizeInBytes: number) =>
  sizeInBytes ? `${(sizeInBytes / 1024 ** 2)?.toFixed(2)} MB` : '0 MB'

const setRouterHistory = (history: any) => (routerHistory = history)
const generateAzureUploadUrl = (tenantId: string) => {
  const azureSASUrl =
    process.env.REACT_APP_AZURE_FILE_CONTAINER_URL +
    '/' +
    tenantId +
    '?' +
    process.env.REACT_APP_AZURE_FILE_CONTAINER_SAS_TOKEN
  return azureSASUrl
}

const downloadBlob = async (folderId: any, fileData: any) => {
  try {
    //const blobServiceClient = new BlobServiceClient(process.env.REACT_APP_AZURE_SAS_URL || '')
    const blobServiceClient = new BlobServiceClient(
      generateAzureUploadUrl('d8971718-5aad-480c-8dd0-eecf50e93a93') || '',
    )
    const containerClientUrl = `/inarchive/${folderId}`
    const containerClient = blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(fileData?.file_name)

    const response = await blobClient.download()

    const blob = await response.blobBody
    return blob
  } catch (error) {
    console.error('Error downloading blob:', error)
  }
}

const statusChecker = async (url: string) => {
  const checkStatus = async (): Promise<any> => {
    const statusResponse = await fetch(url)
    const statusResponseJson = await statusResponse.json()
    if (statusResponseJson.runtimeStatus === "Running" || statusResponseJson.runtimeStatus === "Pending") {
      await new Promise((resolve) => setTimeout(resolve, 5000))
      const value = await checkStatus()
      return value
    } else {
      localStorage.setItem('prompt_history_id', statusResponseJson?.output?.prompt_history_id)
      return statusResponseJson?.output
    }
  }
  if (url) {
    const response = await checkStatus()
    return response
  }
}

const getValueFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key)
  if (localStorageValue !== null && localStorageValue !== '') {
    try {
      return JSON.parse(localStorageValue)
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error)
      return null
    }
  } else {
    return null
  }
}

const addValueToLocalStorage = (key: string, jsonValue: any) =>
  localStorage.setItem(key, JSON.stringify(jsonValue))

const uploadToLocalStorage = (filesNames: any) => {
  const excistingFiles = getFromLocalStorage()
  if (excistingFiles?.length > 0) {
    const updatedFiles = [...excistingFiles, ...filesNames]
    localStorage.setItem('filesToUpload', JSON.stringify(updatedFiles))
  } else {
    localStorage.setItem('filesToUpload', JSON.stringify(filesNames))
  }
}

const getAccessLists = (token: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    try {
      const tokenDecoded: any = jwt_decode<ITokenDecoded>(token);

      if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {
        const tokenUserAccessList = tokenDecoded?.extension_UserAccess;
        const arrayOfPermissions = tokenUserAccessList.split(", ");
        resolve(arrayOfPermissions);
      } else {
        resolve([]); // or resolve with any default value
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      reject(error); // Reject the promise with the error
    }
  });
};

const getTenantId = () => {
  let tenantId
  const token = localStorage.getItem('id_token')

  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_TenantId
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getTenantIdwithtokenId = (token: any) => {
  let tenantId
  // const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_TenantId
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getUserRole = () => {
  let tenantId
  const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_UserRole
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}

const getUserRoleFromToken = (token: any) => {
  let tenantId
  // const token = localStorage.getItem('id_token')
  if (token) {
    const tokenDecoded: any = jwt_decode<ITokenDecoded>(token)
    if (typeof tokenDecoded === 'object' && tokenDecoded !== null) {

      // const tokenDecodedSplitted =tokenDecoded.iss
      // const uuidpattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      // tenantId  = tokenDecodedSplitted.match(uuidpattern);
      tenantId = tokenDecoded.extension_UserRole
      //tenantId = tokenDecodedSplitted?.[3]
    }
  }
  return tenantId
}




const getFromLocalStorage = () => {
  const data = JSON.parse(localStorage.getItem('filesToUpload') || ' {}')
  return data
}
const getFileType = (file_name: string) => {
  try {
    const [, file_extension] = file_name.split('.')
    const lowerCaseExtension = file_extension.toLowerCase()

    const fileTypes: Record<string, string> = {
      '.txt': 'TEXT',
      '.pdf': 'PDF',
      '.csv': 'CSV',
      '.docx': 'WORD',
      '.doc': 'WORD',
      '.ppt': 'POWERPOINT',
      '.pptx': 'POWERPOINT',
      '.xls': 'EXCEL',
      '.xlsx': 'EXCEL',
      '.json': 'JSON',
      // Add more file types and extensions as needed
    }

    return fileTypes[`.${lowerCaseExtension}`] || 'Unknown'
  } catch (e) {
    console.error(`An error occurred: ${e}`)
    throw e
  }
}

function generateNodeSequenceResponse(req: any) {

  const inputEdges = req.edges;

  const mapping = inputEdges.reduce((result: any, item: any) => {
    result[item.source] = item.target;
    return result;
  }, {});

  const nodeSequence = [];
  let current_node = 'start';
  while (current_node in mapping) {
    nodeSequence.push(current_node);
    current_node = mapping[current_node];
  }
  nodeSequence.push(current_node);

  return nodeSequence;
}

const dropDownConverter = (value: string[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: "App Version " + item,
      value: item,
    }))
  return result
}

// const uploadCheck = (name: any, fileInfo: any, type?: any) => {

//   const { dispatch } = store ?? {}

//   const MAX_IMAGE_SIZE_MB = 2;
//   const MAX_AUDIO_SIZE_MB = 5;
//   const MAX_DOCUMENT_SIZE_MB = 10;
//   const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
//   const ALLOWED_AUDIO_TYPES = ['audio/mpeg', 'audio/wav'];
//   const ALLOWED_DOCUMENT_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv'];

//   const maxImageSizeBytes = MAX_IMAGE_SIZE_MB * 1024 * 1024;
//   const maxAudioSizeBytes = MAX_AUDIO_SIZE_MB * 1024 * 1024;
//   const maxDocumentSizeBytes = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;

//   const checkFileSize = (fileSize: number, maxSizeBytes: number, typeDescription: string) => {
//     if (fileSize > maxSizeBytes) {
//       dispatch.commonModel.updateNotificationState({
//         message: 'Failed',
//         description: `${typeDescription} file size exceeds the limit`,
//         type: 'error',
//       });
//       console.error(`${typeDescription} file size exceeds the limit`);
//       return false;
//     }
//     return true;
//   };

//   const checkFileType = (fileType: string, allowedTypes: string[], typeDescription: string) => {
//     console.log('fileType', fileType);

//     if (!allowedTypes.includes(fileType)) {
//       console.log('fileInfo.type', fileInfo);
//       dispatch.commonModel.updateNotificationState({
//         message: 'Failed',
//         description: `Invalid ${typeDescription} file type.`,
//         type: 'error',
//       });
//       return false;
//     }
//     return true;
//   };

//   if (type === 6) { // Image files
//     if (!checkFileSize(fileInfo?.file?.size, maxImageSizeBytes, 'Image') || !checkFileType(fileInfo?.file?.type, ALLOWED_IMAGE_TYPES, 'image')) {
//       return false;
//     }
//   } else if (type === 7) { // Audio files
//     if (!checkFileSize(fileInfo?.file?.size, maxAudioSizeBytes, 'Audio') || !checkFileType(fileInfo?.file?.type, ALLOWED_AUDIO_TYPES, 'audio')) {
//       return false;
//     }
//   } else { // Document files
//     if (!checkFileSize(fileInfo?.file?.size, maxDocumentSizeBytes, 'Document') || !checkFileType(fileInfo?.file?.type, ALLOWED_DOCUMENT_TYPES, 'document')) {
//       return false;
//     }
//   }
// }


const uploadCheck = (type?: any, fileInfo?: any) => {

  const { dispatch } = store ?? {}

  const MAX_IMAGE_SIZE_MB = 2;
  const MAX_AUDIO_SIZE_MB = 5;
  const MAX_DOCUMENT_SIZE_MB = 10;

  const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
  const ALLOWED_AUDIO_TYPES = ['audio/mpeg', 'audio/wav'];
  const ALLOWED_DOCUMENT_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv', 'text/xml','text/plain'];



  if (type == 6) {
    // check file size    
    const maxImageSizeBytes = MAX_IMAGE_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxImageSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'File size exceeds the 2MB limit',
        type: 'error',
      })
      return false;
    }

    // Check file type
    if (!ALLOWED_IMAGE_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid file type. Upload a valid file type.',
        type: 'error',
      })
      return false;
    }
    else {
      return true
    }
  }
  if (type === 7) { // Assuming type 7 represents audio files
    // Check file size for audio
    const maxAudioSizeBytes = MAX_AUDIO_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxAudioSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Audio file size exceeds the 5MB limit',
        type: 'error',
      });
      console.error('Audio file size exceeds the 5MB limit');
      return false;
    }

    // Check file type for audio
    if (!ALLOWED_AUDIO_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid audio file type. Only MP3 and WAV files are allowed',
        type: 'error',
      });
      return false;
    }
    else {
      return true
    }
  }else{    
    const maxDocSizeBytes = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;
    if (fileInfo?.size > maxDocSizeBytes) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Doc file size exceeds the 10MB limit',
        type: 'error',
      });
      console.error('Doc file size exceeds the 10MB limit');
      return false;
    }

    // Check file type for audio
    if (!ALLOWED_DOCUMENT_TYPES.includes(fileInfo?.type)) {
      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Invalid doc file type.',
        type: 'error',
      });
      return false;
    }
    else {
      return true
    }
  }
}

const FilterValues = [
  { label: '==', value: '$eq' },
  { label: '!=', value: '$ne' },
  { label: '>', value: '$gt' },
  { label: '>=', value: '$ge' },
  { label: '<', value: '$lt' },
  { label: '<=', value: '$le' },
  { label: 'in', value: '$in' },
  { label: 'not in', value: '$notin' },
]

const pastTickets = [
  {
    name: 'Default',
    desc: '',
    date: 'June 15, 11.39 PM'
  },
  {
    name: 'Tech Support',
    desc: '',
    date: 'June 15, 11.39 PM'
  }
  , {
    name: 'Sales',
    desc: '',
    date: 'June 15, 11.39 PM'
  }
]

const tickets = [
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 1,
    subject: "Website Down",
    category: "Technical",
    ticketPriority: "High",
    raisedBy: "John Doe",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 2,
    subject: "Email Configuration Issue",
    category: "Technical",
    ticketPriority: "Medium",
    raisedBy: "Jane Smith",
    createdOn: "2024/01/19"
  },
  {
    ticketId: 3,
    subject: "Access Permission Request",
    category: "Administrative",
    ticketPriority: "Low",
    raisedBy: "Alice Johnson",
    createdOn: "2024/01/19"
  },
  // Add more objects as needed
];

const healthTable = [{
  customer: "Google",
  total_tickets: 175,
  open_tickets: 6,
  escalation: 35,
  time_for_resolve: 47,
  sentiment: 60,
  health: 20
},
{
  customer: "Facebook",
  total_tickets: 175,
  open_tickets: 6,
  escalation: 80,
  sentiment: 90,
  time_for_resolve: 4,
  health: 60
}]


const LanguageOptions = [
  "Afar",
  "Abkhazian",
  "Afrikaans",
  "Akan",
  "Amharic",
  "Aragonese",
  "Arabic",
  "Assamese",
  "Avar",
  "Aymara",
  "Azerbaijani",
  "Bashkir",
  "Belarusian",
  "Bulgarian",
  "Bihari",
  "Bislama",
  "Bambara",
  "Bengali",
  "Tibetan",
  "Breton",
  "Bosnian",
  "Catalan",
  "Chechen",
  "Chamorro",
  "Corsican",
  "Cree",
  "Czech",
  "Old Church Slavonic / Old Bulgarian",
  "Chuvash",
  "Welsh",
  "Danish",
  "German",
  "Divehi",
  "Dzongkha",
  "Ewe",
  "Greek",
  "English",
  "Esperanto",
  "Spanish",
  "Estonian",
  "Basque",
  "Persian",
  "Peul",
  "Finnish",
  "Fijian",
  "Faroese",
  "French",
  "West Frisian",
  "Irish",
  "Scottish Gaelic",
  "Galician",
  "Guarani",
  "Gujarati",
  "Manx",
  "Hausa",
  "Hebrew",
  "Hindi",
  "Hiri Motu",
  "Croatian",
  "Haitian",
  "Hungarian",
  "Armenian",
  "Herero",
  "Interlingua",
  "Indonesian",
  "Interlingue",
  "Igbo",
  "Sichuan Yi",
  "Inupiak",
  "Ido",
  "Icelandic",
  "Italian",
  "Inuktitut",
  "Japanese",
  "Javanese",
  "Kongo",
  "Kikuyu",
  "Kuanyama",
  "Kazakh",
  "Greenlandic",
  "Cambodian",
  "Kannada",
  "Korean",
  "Kanuri",
  "Kashmiri",
  "Kurdish",
  "Komi",
  "Cornish",
  "Kirghiz",
  "Latin",
  "Luxembourgish",
  "Ganda",
  "Limburgian",
  "Lingala",
  "Laotian",
  "Lithuanian",
  "Latvian",
  "Malagasy",
  "Marshallese",
  "Maori",
  "Macedonian",
  "Malayalam",
  "Mongolian",
  "Moldovan",
  "Marathi",
  "Malay",
  "Maltese",
  "Burmese",
  "Nauruan",
  "North Ndebele",
  "Nepali",
  "Ndonga",
  "Dutch",
  "Norwegian Nynorsk",
  "Norwegian",
  "South Ndebele",
  "Navajo",
  "Chichewa",
  "Occitan",
  "Ojibwa",
  "Oromo",
  "Oriya",
  "Ossetian",
  "Punjabi",
  "Pali",
  "Polish",
  "Pashto",
  "Portuguese",
  "Quechua",
  "Raeto Romance",
  "Kirundi",
  "Romanian",
  "Russian",
  "Rwandi",
  "Sanskrit",
  "Sardinian",
  "Sindhi",
  "Sango",
  "Serbo-Croatian",
  "Sinhalese",
  "Slovak",
  "Slovenian",
  "Samoan",
  "Shona",
  "Somalia",
  "Albanian",
  "Serbian",
  "Swati",
  "Southern Sotho",
  "Sundanese",
  "Swedish",
  "Swahili",
  "Tamil",
  "Telugu",
  "Tajik",
  "Thai",
  "Tigrinya",
  "Turkmen",
  "Tagalog",
  "Tswana",
  "Tonga",
  "Turkish",
  "Tsonga",
  "Tatar",
  "Twi",
  "Tahitian",
  "Uyghur",
  "Urdu",
  "Venda",
  "Vietnamese",
  "Volapük",
  "Walloon",
  "Wolof",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang",
  "Chinese",
  "Zulu"
]

function getAllValues() {

  return {
      currentMonthStartDate: 1577817000000,
      currentMonthEndDate: 1893436200000,
      previousMonthStartDate: 1577817000000,
      previousMonthEndDate: 1893436200000
  };
}

function getMonthRangeConversation() {
  const today = new Date();
  const thirtyOneDaysAgo = new Date();
  
  // Subtract 31 days from the current date
  thirtyOneDaysAgo.setDate(today.getDate() - 31);

  console.log('hi', today.getTime());
  
  
  return {
    currentMonthStartDate: thirtyOneDaysAgo.getTime(),
    currentMonthEndDate: today.getTime()
  };
}

function getMonthRange() {
  // const today = new Date();
  // const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  // return {
  //     startDate: oneMonthAgo.getTime(),
  //     endDate: today.getTime()
  // };


  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 30); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 30); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // const today = new Date();
  // const currentMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
  // const currentMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month

  // const previousMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1); // First day of the previous month
  // const previousMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1682879400000,
  //   previousMonthStartDate: 1669833000000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
      currentMonthStartDate: oneWeekAgo.getTime(),
      currentMonthEndDate: today.getTime(),
      previousMonthStartDate: twoWeeksAgo.getTime(),
      previousMonthEndDate: oneWeekAgo.getTime()
  };
}

function getThreeMonthRange() {
  // const today = new Date();
  // const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  // return {
  //     startDate: oneMonthAgo.getTime(),
  //     endDate: today.getTime()
  // };
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 90); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 90); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);
  console.log('hi');
  // const today = new Date();
  // const currentMonthStartDate = new Date(today.getFullYear(), today.getMonth()-2, 1); // First day of the current month
  // const currentMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month

  // const previousMonthStartDate = new Date(today.getFullYear(), today.getMonth() -1, 1); // First day of the previous month
  // const previousMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1682879400000,
  //   previousMonthStartDate: 1669833000000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
      currentMonthStartDate: oneWeekAgo.getTime(),
      currentMonthEndDate: nextDay.getTime(),
      previousMonthStartDate: twoWeeksAgo.getTime(),
      previousMonthEndDate: oneWeekAgo.getTime()
  };
}



function getWeekRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight for consistent date calculation

  const oneWeekAgo = new Date(today.getTime());
  oneWeekAgo.setDate(today.getDate() - 7); // Set to exactly one week ago

  const twoWeeksAgo = new Date(oneWeekAgo.getTime());
  twoWeeksAgo.setDate(oneWeekAgo.getDate() - 7); // Set to exactly two weeks ago

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // return {
  //   currentMonthStartDate: 1675189800000,
  //   currentMonthEndDate: 1677609000000,
  //   previousMonthStartDate: 1672511400000,
  //   previousMonthEndDate: 1675189800000
  // }
  return {
    currentMonthStartDate: oneWeekAgo.getTime(),
    currentMonthEndDate: nextDay.getTime(),
    previousMonthStartDate: twoWeeksAgo.getTime(),
    previousMonthEndDate: oneWeekAgo.getTime()
  };
}

function getDayRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's date to midnight to ensure the full day is covered

  const oneDayAgo = new Date(today.getTime());
  oneDayAgo.setDate(today.getDate() - 1); // Set to exactly one day ago at midnight

  const twoDaysAgo = new Date(oneDayAgo.getTime());
  twoDaysAgo.setDate(oneDayAgo.getDate() - 1); // Set to two days ago from today at midnight

  const nextDay = new Date(today.getTime());
  nextDay.setDate(today.getDate() + 1);

  // return {
  //   currentMonthStartDate: 1676917800000,
  //   currentMonthEndDate: 1677609000000,
  //   previousMonthStartDate: 1676313000000,
  //   previousMonthEndDate: 1676917800000
  // }

  return {
    currentMonthStartDate: today.getTime(),
    currentMonthEndDate: nextDay.getTime(),
    previousMonthStartDate: twoDaysAgo.getTime(),
    previousMonthEndDate: oneDayAgo.getTime()
  };


}
function getCurrentTimeChatBot() {
  const date = new Date();
  return format(date, 'dd MMM yyyy HH:mm');
}

function convertMillisToDateString(milliseconds: any) {
  // Create a new Date object with the given milliseconds
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day of the month
  const day = date.getDate();

  // Get the month name from the monthNames array
  const month = monthNames[date.getMonth()];

  // Get the last two digits of the year
  const year = date.getFullYear().toString().slice(-2);

  // Return the formatted string
  return `${day} ${month} ${year}`;
}

function getFormatedDate(timestamp: any)  {
   const date = new Date(timestamp);

  // Format the date and time
  const formattedDate = date.toLocaleString('en-US', {
      month: 'long', // Full month name
      day: 'numeric', // Numeric day
      year: 'numeric', // Full year
      hour: 'numeric', // Hour in 12-hour format
      minute: 'numeric', // Minutes
      // second: 'numeric', // Seconds
      hour12: true // 12-hour format
  });

  return formattedDate.replace('at', ''); // Remove the comma after the day
}

const getQuestion = (question: string) => {
  console.log('question', question);
  
  const regex = new RegExp(
    `^Default language : [^;]+;(.+)$`
  );
  const match = question.match(regex);
  console.log('match', match);
  if (match) {
    return match[1].trim();
  } else {
    return ""; // or handle the error appropriately
  }
};

export {
  routerHistory,
  extractIdToken,
  getUserRoleFromToken,
  setRouterHistory,
  convertBytestoMB,
  statusChecker,
  uploadToLocalStorage,
  getFromLocalStorage,
  downloadBlob,
  getValueFromLocalStorage,
  addValueToLocalStorage,
  getTenantId,
  getFileType,
  generateNodeSequenceResponse,
  dropDownConverter,
  getAccessLists,
  FilterValues,
  pastTickets,
  tickets,
  getUserRole,
  getMonthRangeConversation,
  getMonthRange,
  getWeekRange,
  getDayRange,
  healthTable,
  getTenantIdwithtokenId,
  getCurrentTimeChatBot,
  uploadCheck,
  convertMillisToDateString,
  getFormatedDate,
  getAllValues,
  LanguageOptions,
  getQuestion,
  getThreeMonthRange
}
